import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'
import { REHYDRATE } from 'redux-persist/lib/constants'

import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  AUTH_LOGIN: createSagaAction('AUTH_LOGIN'),
  AUTH_UPDATE_USER: 'AUTH_UPDATE_USER',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_RESET_PASSWORD_REQUEST: createSagaAction('AUTH_RESET_PASSWORD_REQUEST')
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  login: (username, password) => ({
    type: constants.AUTH_LOGIN.ACTION,
    username,
    password
  }),
  updateUser: user => ({
    type: constants.AUTH_UPDATE_USER,
    user
  }),
  logout: () => ({
    type: constants.AUTH_LOGOUT
  }),
  resetPasswordRequest: (email) => ({
    type: constants.AUTH_RESET_PASSWORD_REQUEST.ACTION,
    email
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // REHYDRATE
  [REHYDRATE]: (state, action) => {
    const persisted = _.get(action, 'payload.auth', {})

    return {
      ...state,
      token: persisted.token || initialState.token,
      token_id: persisted.token_id || initialState.token_id,
      user: persisted.user || initialState.user
    }
  },

  // AUTH_LOGIN
  [constants.AUTH_LOGIN.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },

  [constants.AUTH_LOGIN.SUCCESS]: (state, action) => {
    return {
      ...state,
      token: action.payload.token,
      token_id: action.payload.token_id,
      user: {
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        roles: action.payload.role,
        accesses: action.payload.accesses
      },
      isLoading: false
    }
  },

  [constants.AUTH_LOGIN.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // AUTH_UPDATE_USER
  [constants.AUTH_UPDATE_USER.ACTION]: (state, action) => {
    return {
      ...state, error: false, isLoading: false,
      user: Object.assign({ ...state.user }, action.user)
    }
  },

  [constants.AUTH_UPDATE_USER]: (state, action) => {
    return {
      ...state, error: false, isLoading: false,
      user: Object.assign({ ...state.user }, action.user)
    }
  },

  [constants.AUTH_LOGOUT]: () => {
    return initialState
  },

  // AUTH_RESET_PASSWORD_REQUEST
  [constants.AUTH_RESET_PASSWORD_REQUEST.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.AUTH_RESET_PASSWORD_REQUEST.SUCCESS]: (state) => {
    return { ...state, isLoading: false }
  },
  [constants.AUTH_RESET_PASSWORD_REQUEST.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false,
  token: null,
  token_id: null,
  user: null
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
