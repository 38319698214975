import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  COURSE_CLASS_FETCH_ALL_SIMPLE: createSagaAction('COURSE_CLASS_FETCH_ALL_SIMPLE'),
  COURSE_CLASS_FETCH: createSagaAction('COURSE_CLASS_FETCH'),
  COURSE_CLASS_FETCH_BY_ID: createSagaAction('COURSE_CLASS_FETCH_BY_ID'),
  COURSE_CLASS_ADD: createSagaAction('COURSE_CLASS_ADD'),
  COURSE_CLASS_EDIT: createSagaAction('COURSE_CLASS_EDIT'),
  COURSE_CLASS_ACTIVE_TOGGLE: createSagaAction('COURSE_CLASS_ACTIVE_TOGGLE'),
  COURSE_CLASS_REMOVE: createSagaAction('COURSE_CLASS_REMOVE'),
  COURSE_CLASS_ENROLL: createSagaAction('COURSE_CLASS_ENROLL'),
  COURSE_CLASS_UNENROLL: createSagaAction('COURSE_CLASS_UNENROLL'),
  COURSE_CLASS_UNENROLL_STUDENT: createSagaAction('COURSE_CLASS_UNENROLL_STUDENT'),
  COURSE_CLASS_REMOVE_EXTRACOURSE: createSagaAction('COURSE_CLASS_REMOVE_EXTRACOURSE'),
  COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID: createSagaAction('COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID'),
  COURSE_CLASS_FETCH_ALL_SERVICES: createSagaAction('COURSE_CLASS_FETCH_ALL_SERVICES'),
  COURSE_CLASS_FETCH_ALL_PRODUCTS: createSagaAction('COURSE_CLASS_FETCH_ALL_PRODUCTS'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchCourseClasses: (currentPage, itemsPerPage, filters, next) => ({
    type: constants.COURSE_CLASS_FETCH.ACTION,
    currentPage,
    itemsPerPage,
    filters,
    next
  }),
  fetchSimpleCourseClasses: (filters, next) => ({
    type: constants.COURSE_CLASS_FETCH_ALL_SIMPLE.ACTION,
    filters,
    next
  }),
  fetchCourseClassesById: (id, next) => ({
    type: constants.COURSE_CLASS_FETCH_BY_ID.ACTION,
    id,
    next
  }),
  fetchEnrollmentsById: (idCourseClass, filters = {}, next) => ({
    type: constants.COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID.ACTION,
    idCourseClass,
    filters,
    next
  }),
  addCourseClass: (formData, next) => (
    {
    type: constants.COURSE_CLASS_ADD.ACTION,
    formData,
    next
  }),
  editCourseClass: (formData, next) => ({
    type: constants.COURSE_CLASS_EDIT.ACTION,
    formData,
    next
  }),
  activeCourseClassToggle: (id, active, next) => ({
    type: constants.COURSE_CLASS_ACTIVE_TOGGLE.ACTION,
    id,
    active,
    next
  }),
  removeCourseClass: (id, next) => ({
    type: constants.COURSE_CLASS_REMOVE.ACTION,
    id,
    next
  }),
  enroll: (enrollments, next) => {
    return ({
      type: constants.COURSE_CLASS_ENROLL.ACTION,
      enrollments,
      next
    })
  },
  unEnroll: (formData, next) => {
    return ({
      type: constants.COURSE_CLASS_UNENROLL.ACTION,
      formData,
      next
    })
  },
  unEnrollStudent: (id_turma, formData, next) => {
    return ({
      type: constants.COURSE_CLASS_UNENROLL_STUDENT.ACTION,
      id_turma,
      formData,
      next
    })
  },
  removeExtraCourse: (id_turma, formData, next) => {
    return ({
      type: constants.COURSE_CLASS_REMOVE_EXTRACOURSE.ACTION,
      id_turma,
      formData,
      next,
    })
  },
  fetchAllServices: (success, error) => ({
    type: constants.COURSE_CLASS_FETCH_ALL_SERVICES.ACTION,
    success,
    error
  }),
  fetchAllProducts: (success, error) => ({
    type: constants.COURSE_CLASS_FETCH_ALL_PRODUCTS.ACTION,
    success,
    error
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // COURSE_CLASS_FETCH
  [constants.COURSE_CLASS_FETCH.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_FETCH.SUCCESS]: (state, action) => {
    //
    // workaround to separate pagination info
    var pagination = Object.assign({}, action.payload);
    delete pagination.data;

    return {
      ...state,
      data: action.payload.data,
      pagination: pagination,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_FETCH.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
  [constants.COURSE_CLASS_FETCH_ALL_SIMPLE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_FETCH_ALL_SIMPLE.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_FETCH_ALL_SIMPLE.FAILED]: (state) => {
    return { ...state, error: true, data: []}
  },
  // COURSE_CLASS_FETCH_BY_ID
  [constants.COURSE_CLASS_FETCH_BY_ID.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      courseClassDetails: { ...action.payload },
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // COURSE_CLASS_ADD
  [constants.COURSE_CLASS_ADD.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_ADD.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.userServiceReturn,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_ADD.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },


  // COURSE_CLASS_EDIT
  [constants.COURSE_CLASS_EDIT.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.userServiceReturn,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_EDIT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // COURSE_CLASS_ACTIVE_TOGGLE
  [constants.COURSE_CLASS_ACTIVE_TOGGLE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_ACTIVE_TOGGLE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_ACTIVE_TOGGLE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // COURSE_CLASS_REMOVE
  [constants.COURSE_CLASS_REMOVE.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_REMOVE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // ENROLL
  [constants.COURSE_CLASS_ENROLL.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_ENROLL.SUCCESS]: (state, action) => {
    return {
      ...state,
      enrolles: action.payload,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_ENROLL.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // UNENROLL
  [constants.COURSE_CLASS_UNENROLL.ACTION]: state => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.COURSE_CLASS_UNENROLL.SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoading: false
    }
  },
  [constants.COURSE_CLASS_UNENROLL.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },
  // COURSE_CLASS_UNENROLL_STUDENT
  [constants.COURSE_CLASS_UNENROLL_STUDENT.ACTION]: state => {
    return { ...state, error: false, isLoadingUnenrollStudent: true }
  },
  [constants.COURSE_CLASS_UNENROLL_STUDENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoadingUnenrollStudent: false
    }
  },
  [constants.COURSE_CLASS_UNENROLL_STUDENT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoadingUnenrollStudent: false }
  },
  // COURSE_CLASS_REMOVE_EXTRACOURSE
  [constants.COURSE_CLASS_REMOVE_EXTRACOURSE.ACTION]: state => {
    return { ...state, error: false, isLoadingRemoveExtraClass: true }
  },
  [constants.COURSE_CLASS_REMOVE_EXTRACOURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      isLoadingRemoveExtraClass: false
    }
  },
  [constants.COURSE_CLASS_REMOVE_EXTRACOURSE.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoadingRemoveExtraClass: false }
  },
  // COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID
  [constants.COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID.ACTION]: (state) => {
    return {
      ...state,
      fetchingEnrollmentsById: true
    };
  },
  [constants.COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      fetchingEnrollmentsById: false,
      enrollments: action.payload
    };
  },
  [constants.COURSE_CLASS_FETCH_ENROLLMENTS_BY_ID.FAILED]: (state) => {
    return {
      ...state,
      fetchingEnrollmentsById: false
    };
  },

  [constants.COURSE_CLASS_FETCH_ALL_SERVICES.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSE_CLASS_FETCH_ALL_SERVICES.FAILED]: (state) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.COURSE_CLASS_FETCH_ALL_SERVICES.SUCCESS]: (state, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      fetchAllServices: [...action.payload],
      ...action.payload,
    };
  },

  [constants.COURSE_CLASS_FETCH_ALL_PRODUCTS.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true };
  },
  [constants.COURSE_CLASS_FETCH_ALL_PRODUCTS.FAILED]: (state) => {
    return { ...state, error: false, isLoading: false };
  },
  [constants.COURSE_CLASS_FETCH_ALL_PRODUCTS.SUCCESS]: (state, action) => {
    return {
      ...state,
      error: false,
      isLoading: false,
      fetchAllProducts: [...action.payload],
      ...action.payload,
    };
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false,
  fetchingEnrollmentsById: false,
  enrollments: []
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : { ...state, isLoading: false }
})
