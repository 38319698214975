import store from "../../redux/store";

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    id_access: 'all',
    onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Educação',
    icon: 'cil-education',
    id_access: [1,2,3,4,5,6],
    show: true,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Cursos',
        to: '/cursos',
        icon: 'cil-notes',
        id_access: 2,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Aulas',
        to: '/aulas',
        icon: 'cil-book',
        id_access: 3,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Questões',
        to: '/questoes',
        icon: 'cil-folder-open',
        id_access: 4,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Alunos',
        to: '/aluno',
        icon: 'cil-user',
        id_access: 5,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Leads',
        to: '/leads',
        icon: 'cil-user',
        id_access: 103,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Turmas',
        to: '/courseclass',
        icon: 'cil-group',
        id_access: 6,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Gabarito CFP',
        to: '/gabarito-cfp',
        icon: 'cil-notes',
        id_access: 102,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Calendário de Provas',
        to: '/scheduled-tests',
        icon: 'cil-calendar',
        id_access: 105,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Notificações de Push',
        badge: {
          color: 'info',
          text: 'NOVO',
        },
        to: '/notifications',
        icon: 'cil-bell',
        id_access: 105,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Operação',
    icon: 'cil-cog',
    id_access: [7,8,9],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Descontos',
        to: '/descontos',
        icon: 'cil-tags',
        id_access: 7,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Pagamentos',
        to: '/pagamentos',
        icon: 'cil-dollar',
        id_access: 8,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Afiliados',
        to: '/afiliados',
        icon: 'cil-user',
        id_access: 9,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      }
    ]
  },
  /* {
    _tag: 'CSidebarNavDropdown',
    name: 'CRM',
    id_access: [100,101,102],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Prospectar',
        to: '/crm-prospections',
        icon: 'cil-dollar',
        id_access: 100,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Agentes de Venda',
        to: '/crm-sales-agents',
        icon: 'cil-group',
        id_access: 101,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'Painel Leads Convertidos',
        to: '/crm-leads-panel',
        icon: 'cil-speedometer',
        id_access: 102,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      }
    ]
  }, */
  {
    _tag: 'CSidebarNavDropdown',
    name: 'App Quiz',
    icon: 'cil-screen-smartphone',
    id_access: [104],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Questões',
        to: '/quiz-questions',
        icon: 'cil-folder-open',
        id_access: 104,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Gerador de Relatório',
    icon: 'cil-copy',
    id_access: [106,107,108],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'CFP Revisão PNG',
        to: '/report-generator-cfp-weekly/png',
        icon: 'cil-file',
        id_access: 106,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'CFP Revisão PDF',
        to: '/report-generator-cfp-weekly/pdf',
        icon: 'cil-file',
        id_access: 107,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      },{
        _tag: 'CSidebarNavItem',
        name: 'CFP Simulado',
        to: '/report-generator-cfp-simulated/pdf',
        icon: 'cil-file',
        id_access: 108,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Sistema',
    icon: 'cil-devices',
    id_access: [10],
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Users',
        to: '/users',
        icon: 'cil-lock-locked',
        id_access: 10,
        onClick: () => store.dispatch({ type: 'VERIFY_TOKEN_ACTION' })
      }
    ]
  }
]
