import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import PropTypes from "prop-types"

import * as authSelectors from "../redux/selectors/auth"
import { actions as authActions } from "../redux/modules/auth"

const propTypes = {
  allowedRoles: PropTypes.array,
  authToken: PropTypes.string,
  user: PropTypes.any,
  component: PropTypes.any,
  logout: PropTypes.func.isRequired,
}

const ProtectedRoute = ({ component: Component, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'VERIFY_TOKEN_ACTION' });
  }, [dispatch])

  return (
    <Route
      {...props}
      render={(routeProps) => {
        const { allowedRoles, authToken, logout, user } = props
        const roles = user && user.roles ? user.roles : []
        const rolesArray = roles.includes(",") ? roles.split(",") : roles;
        const allowed = allowedRoles.filter((element) =>
          rolesArray.includes(element)
        );
  
        if (authToken) {
          if (allowedRoles && allowed) {
            return <Component {...routeProps} />
          } else if (allowedRoles && !allowed) {
            logout()
          } else {
            return <Component {...routeProps} />
          }
        }
  
        // Conditions not met, sendign to login page
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location }
            }}
          />
        )
      }}
    />
  )
}


const mapStateToProps = (state, props) => ({
  authToken: authSelectors.token(state, props),
  authTokenId: authSelectors.tokenId(state, props),
  user: authSelectors.user(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(authActions.logout())
})

ProtectedRoute.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
