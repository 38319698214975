import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { routerReducer } from 'react-router-redux'

import { persistStore } from 'redux-persist'
import { persistRootReducer } from './persistor'
import reducers from './modules'
import rootSaga from './sagas'
import { VerifyToken } from './middlewares/VerifyToken'

// Initialize the Redux set up
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistRootReducer(
  combineReducers({ ...reducers, routing: routerReducer })
)
const store = createStore(
  persistedReducer,
  undefined,
  composeEnhancers(applyMiddleware(VerifyToken, sagaMiddleware))
)

store.dispatch({ type: 'VERIFY_TOKEN_ACTION' });

const persistor = persistStore(store)

// Run the saga now
sagaMiddleware.run(rootSaga)

const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export { persistor, store, store as default, useAppDispatch }

