import request from '../../shared/request'
import { generateFilters } from '../../helpers'

/*
Lista simples de TODAS as turmas disponíveis sem paginação
Ex.: /v1/services/turmas/fetchAll.
*/
export function fetchAllSimple(filters) {
  return request(`v1/services/turmas/findAll`, {
    qsParams: {
      ...filters
    }
  })
}

/*
Lista simples de TODAS as turmas. Conta com os filtros básicos de paginação ( page, per_page ).
A paginação padrão é 25 por request
Ex.: /v1/services/turmas?per_page=5
*/
export function fetchAll(currentPage, itemsPerPage, filters = {}) {
  return request(`v1/services/turmas`, {
    qsParams: {
      per_page: itemsPerPage,
      page: currentPage
    },
    filters: generateFilters(filters, 'service')
  })
}

/*
Lista as turmas de um curso
Ex.: /v1/services/cursos/144/turmas
*/
export function fetchById(id) {

  return request(`v1/services/${id}/turmas`)

}

/*
Retorna os detalhes de uma turma
Ex.: /v1/services/turmas/30
*/
export function fetchDetailes(id) {

  return request(`v1/services/turmas/${id}`)

}

export function fetchEnrollmentsById(idCourseClass, qsParams = {}) {
  return request(`v1/services/turmas/${idCourseClass}/enrollments`, {
    qsParams
  });
}

// Cria uma turma
export function add(formData) {
  return request(`v1/services/turmas`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

/*
Atualiza turma/alunos
Ex.:/v1/services/turmas/30
*/
export function edit(formData) {
  return request(`v1/services/turmas/${formData.id_turma}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}


export function activeToggle(id, formData) {
  return request(`v1/services/turmas/${id}/ativa-site`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function remove(id) {
  return request(`v1/services/courseclass/${id}`, {
    method: 'DELETE'
  })
}

export function attachRole(id, formData) {
  return request(`v1/services/courseclass/${id}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function enroll(formData) {
  return request(`v1/services/turmas/enroll`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function unEnroll(formData) {
  return request(`v1/services/turmas/unenroll`, {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function unEnrollStudent(id_turma, aluno) {
  return request(`v1/services/turmas/removeStudentFromClassRoom/${id_turma}`, {
    method: 'DELETE',
    body: JSON.stringify({aluno: aluno})
  })
}

export function removeExtraCourseClass(id_turma, id_extraCourse) {
  return request(`v1/services/turmas/removeAdditionalCourseFromClassRoom/${id_turma}`, {
    method: 'DELETE',
    body: JSON.stringify({curso: id_extraCourse})
  })
}

export function fetchAllServices() {
  return request('v1/contaazul/find-data-conta-azul/services');
};

export function fetchAllProducts() {
  return request('v1/contaazul/find-data-conta-azul/products');
};

export function removeCourseClasses(ids_turma){
  return request(`v1/services/turmas/delete-batch`, {
    method: 'DELETE',
    body: JSON.stringify(ids_turma)
  })
}