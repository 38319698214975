import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/question'
import { constants } from '../modules/question'
import { actions as questionActions } from '../modules/question'
import { actions as classActions } from '../modules/classes'

function* fetchQuestions(action) {
  try {
    const payload = yield call(api.fetchAll, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.QUESTION_FETCH.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchQuestionsTwo(action) {
  try {
    const payload = yield call(api.fetchAllTwo, action.currentPage, action.itemsPerPage, action.filters)
    yield put({ type: constants.QUESTION_FETCH_TWO.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH_TWO.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchQuestionsById(action) {
  try {
    const payload = yield call(api.fetchById, action.id)
    yield put({ type: constants.QUESTION_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchQuestionsByCourse(action) {
  try {
    const payload = yield call(api.fetchByCourse, action.id)
    yield put({ type: constants.QUESTION_FETCH_BY_COURSE.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.QUESTION_FETCH_BY_COURSE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* saveQuestion(action) {
  try {
    notification.info({
      key: 'question_save',
      message: 'Salvando questão'
    });

    const question = yield call(api.save, action.formData);

    yield put({ type: constants.QUESTION_SAVE.SUCCESS, question })

    notification.close('question_save')
    notification.success({
      message: 'Questão salva com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    notification.close('question_save')
    notification.error({
      message: 'Problemas ao salvar a questão',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTION_SAVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* removeQuestion(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'question_remove',
      message: 'Deletando Questão'
    })

    const payload = yield call(api.remove, action.id)
    yield put({ type: constants.QUESTION_REMOVE.SUCCESS, payload })

    // notifications
    notification.close('question_remove')
    notification.success({
      message: 'Questão deletada com sucesso'
    })
    yield put(questionActions.fetchQuestions())
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('question_remove')
    notification.error({
      message: 'Problemas ao deletar a questão',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTION_REMOVE.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* linkToClass(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'question_link',
      message: 'Adicionando questões'
    })

    const payload = yield call(api.linkToClass, action.classId, action.questions)
    yield put({ type: constants.QUESTION_BULK_LINK_TO_CLASS.SUCCESS, payload })

    //
    // refresh current class
    yield put(classActions.fetchClassesById(action.classId))

    // notifications
    notification.close('question_link')
    notification.success({
      message: 'Questões adicionadas com sucesso'
    })
    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('question_link')
    notification.error({
      message: 'Problemas ao adicionar as questões',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTION_BULK_LINK_TO_CLASS.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* verifyNewQuestionCode(action) {
  try {
      const payload = yield call(api.verifyCodeNewQuestion, action.code)

      yield put({ type: constants.QUESTION_VERIFY_NEW_CODE.SUCCESS, payload })

      action.next && action.next(true)
  } catch(e) {
    yield put({
      type: constants.QUESTION_VERIFY_NEW_CODE.FAILED,
      error: true,
      errorMessage: e.message || e.errorMessage || e
    })

    action.next && action.next(false)
  }
}

function* fetchAllFamiliesQuestions(action) {
  try {
    const payload = yield call(api.fetchAllFamiliesQuestions);

    yield put({
      type: constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* fetchAllTagsQuestions(action) {
  try {
    const payload = yield call(api.fetchAllTagsQuestions);

    yield put({
      type: constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* fetchAllExamsQuestions(action) {
  try {
    const payload = yield call(api.fetchAllExamsQuestions);

    yield put({
      type: constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.SUCCESS,
      payload,
    })

    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.FAILED,
      error: true
    });

    notification.error({
      message: e.message || e,
    });
    action.error && action.error();
  }
}

function* fetchModulesByFamilies(action) {
  try {
    const payload = yield call(api.fetchModulesByFamilies, action.families)
    yield put({ type: constants.MODULES_FETCH_BY_FAMILIES, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.MODULES_FETCH_BY_FAMILIES.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* fetchChaptersByModules(action) {
  try {
    const payload = yield call(api.fetchChaptersByModules, action.modules)
    yield put({ type: constants.CHAPTERS_FETCH_BY_MODULES, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.CHAPTERS_FETCH_BY_MODULES.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* generatePdfQuestions(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'questions_pdf',
      message: 'Gerando PDF Questões.'
    })

    const payload = yield call(api.generatePdfQuestions, action.formData);

    yield put({ type: constants.QUESTIONS_PDF_All_SELECTEDS.SUCCESS, payload })

    // notifications
    notification.close('questions_pdf')
    notification.success({
      message: 'PDF gerado com sucesso!'
    })
    action.next && action.next(payload)
  } catch (e) {
    // notifications
    notification.close('questions_pdf')
    notification.error({
      message: 'Falha ao gerar PDF!',
      description: e.message || e
    })
    yield put({
      type: constants.QUESTIONS_PDF_All_SELECTEDS.FAILED,
      error: true,
      errorMessage: e.message || e
    })
  }
}

function* watchFetchQuestions() {
  yield takeLatest(constants.QUESTION_FETCH.ACTION, fetchQuestions)
}

function* watchFetchQuestionsTwo() {
  yield takeLatest(constants.QUESTION_FETCH_TWO.ACTION, fetchQuestionsTwo)
}

function* watchFetchQuestionsById() {
  yield takeLatest(constants.QUESTION_FETCH_BY_ID.ACTION, fetchQuestionsById)
}

function* watchFetchQuestionsByCourse() {
  yield takeLatest(constants.QUESTION_FETCH_BY_COURSE.ACTION, fetchQuestionsByCourse)
}

function* watchSaveQuestion() {
  yield takeLatest(constants.QUESTION_SAVE.ACTION, saveQuestion)
}

function* watchRemoveQuestion() {
  yield takeLatest(constants.QUESTION_REMOVE.ACTION, removeQuestion)
}

function* watchLinkToClass() {
  yield takeLatest(constants.QUESTION_BULK_LINK_TO_CLASS.ACTION, linkToClass)
}

function* watchNewQuestionCode() {
  yield takeLatest(constants.QUESTION_VERIFY_NEW_CODE.ACTION, verifyNewQuestionCode)
}

function* watchfetchAllFamiliesQuestions() {
  yield takeLatest(constants.QUESTIONS_FETCH_All_FAMILIES_QUESTIONS.ACTION, fetchAllFamiliesQuestions);
}

function* watchfetchAllTagsQuestions() {
  yield takeLatest(constants.QUESTIONS_FETCH_All_TAGS_QUESTIONS.ACTION, fetchAllTagsQuestions);
}

function* watchfetchAllExamsQuestions() {
  yield takeLatest(constants.QUESTIONS_FETCH_All_EXAMS_QUESTIONS.ACTION, fetchAllExamsQuestions);
}

function* watchFetchModulesByFamlies() {
  yield takeLatest(constants.MODULES_FETCH_BY_FAMILIES.ACTION, fetchModulesByFamilies)
}

function* watchFetchChaptersByModules() {
  yield takeLatest(constants.CHAPTERS_FETCH_BY_MODULES.ACTION, fetchChaptersByModules)
}

function* watchGeneratePdfQuestions() {
  yield takeLatest(constants.QUESTIONS_PDF_All_SELECTEDS.ACTION, generatePdfQuestions)
}

export function* rootSaga() {
  yield all([
    fork(watchFetchQuestions),
    fork(watchFetchQuestionsTwo),
    fork(watchFetchQuestionsById),
    fork(watchFetchQuestionsByCourse),
    fork(watchSaveQuestion),
    fork(watchRemoveQuestion),
    fork(watchLinkToClass),
    fork(watchNewQuestionCode),
    fork(watchfetchAllFamiliesQuestions),
    fork(watchfetchAllTagsQuestions),
    fork(watchfetchAllExamsQuestions),
    fork(watchFetchModulesByFamlies),
    fork(watchFetchChaptersByModules),
    fork(watchGeneratePdfQuestions)
  ])
}
