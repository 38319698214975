import * as authSelectors from "../selectors/auth";
import { actions as authActions } from "../modules/auth";

import config from "../../config";

export const VerifyToken = (store) => (next) => (action) => {
  if (action.type === "VERIFY_TOKEN_ACTION") {
    const token = authSelectors.token(store.getState());
    const { auth } = JSON.parse(localStorage.getItem("persist:root") || "{}");
    const tokenSendApi = JSON.parse(auth || "{}")?.token || token;

    if (!tokenSendApi) {
      store.dispatch(authActions.logout());
      return;
    }

    fetch(`${config.apiUrl}/v1/check-token-expiration`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokenSendApi}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (
          response.status === 401 ||
          response.status === 403 ||
          response.status === 400
        ) {
          store.dispatch(authActions.logout());
        }
      })
      .catch(() => {
        store.dispatch(authActions.logout());
      });

    return;
  }

  return next(action);
};

export default VerifyToken;
